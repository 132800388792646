import React from 'react'
import PropTypes from 'prop-types'
//import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
//import { Link, graphql } from 'gatsby'
import { Link } from 'gatsby'

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <div key={item.text} className="column is-6">
        <section className="section" style={{paddingBottom:"0px"}}>
          <div className="has-text-centered ef-help-divider" style={{backgroundColor: item.color }}>
          </div>
          <h4>{item.header}</h4>
          <h2 style={{fontSize:"22px"}}>{item.question}</h2>
          <p style={{}}>{item.text}</p>
          <div className="column is-12 has-text-centered"  style={{}}>
            <Link className="btn" to={item.buttonlink} style={{width: "210px", paddingTop: "9px", boxShadow: "none", backgroundColor:item.color, color: "white"}}>
              {item.buttontext}
            </Link>
          </div>

        </section>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
