import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
//import BlogRoll from '../components/BlogRoll'

import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
//import vimeo from '../img/social/vimeo.svg'
import { Helmet } from 'react-helmet'


export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mission1,
  mission2,
  mainpitch,
  mainpitch2,
  mainpitch3,
  request,
  description,
  intro,
  stats,
}) => (
  <div>

  <Helmet
      attributes={{
          class: 'ef-button-report-effectiveness ef-header-current-statistics ef-top-section ef-table ef-table-title ef-center trademark'
      }}
  />
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top center`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '125px',
          lineHeight: '0',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          padding: '0px',
          backgroundColor: '#002366ff'
        }}
      >
        <h3
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
          style={{
            boxShadow:
              '#002366ff 0rem 0px 0px, #002366ff 0rem 0px 0px',
            backgroundColor: '#002366ff',
            color: 'white',
            lineHeight: '1',
            padding: '.4em',
          }}
        >
          {title}
        </h3>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              '#002366ff 0rem 0px 0px, #002366ff 0rem 0px 0px',
            backgroundColor: '#002366ff',
            color: 'white',
            lineHeight: '1',
            padding: '.25em',
            paddingBottom: '20px',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>




    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">


                <div className="content">

                <ul style={{margin: "0px", fontSize: "17px", fontWeight: "normal"}}>
                <li>Made of Nutrient-Dense, Antiviral, and Anti-Inflammatory Foods to Fight Coronavirus Infections</li>
                <li>Flexible, Simple, and Easy to Use</li>
                <li>Designed for Speed to Save Time</li>
                <li>Made of Affordable and Widely Available Foods to Save Money</li>
                <li>Diabetic and Hypertension Friendly</li>
                <li>You Are Helping a Study to Find an Immediate Treatment for COVID-19</li>
                </ul>







                  <div style={{textAlign: 'center', backgroundColorx: "#ff0000", position: "relative", top:"10px"}} className="column is-3x ef-button-report-effectivenessx">
                  <Link className="btn" to="/meal-plans/immunity-meal-plan#top" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
                    Get Free Meal Plan
                  </Link>
                  </div>


















              <div style={{marginTop:"30px", textAlign: "center", backgroundColorx: "#ff0000"}} className="ef-header-current-statisticsx">
                <h1 className="has-text-weight-semibold is-size-2x">The Whole Foods Immunity Study<span className="trademark">&trade;</span></h1>
              </div>

                  <h3 style={{paddingTop: "10px", paddingBottom: "0px", marginTop: "0px", color: "#00693eff", fontWeight: "bold"}}>{mission2}</h3>







                  <div style={{textAlign: 'center', backgroundColorx: "#800080", position: "relative", top:"10px"}} className="column is-3x ef-button-report-effectivenessx">
                  <Link className="btn" to="/forms/report-effectiveness-immunity#top" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#800080", color: "white"}}>
                    Report Effectiveness
                  </Link>
                  </div>


                  <div className="column is-3x" style={{marginTop:"20px", padding: "0px", backgroundColorx: "#ff0000"}}>
                                  <div className="content has-text-centered ef-social-top-sectionx">
                                            Follow for updates
                                  <div className="column is-4x social ef-social-top-sectionx has-text-centered">
                                  <a title="facebook" href="https://www.facebook.com/EffectiveFoods" style={{backgroundColor: '#00693eff'}}>
                                    <img
                                      src={facebook}
                                      alt="Facebook"
                                      style={{ width: '1em', height: '1em', fillx: 'white', backgroundColorx: 'white', filter: 'invert(1)'}}
                                    />
                                  </a>
                                  <a title="twitter" href="https://twitter.com/EffectiveFoods" style={{backgroundColor: '#00693eff'}}>
                                    <img
                                      className="fas fa-lg"
                                      src={twitter}
                                      alt="Twitter"
                                      style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                                    />
                                  </a>
                                  <a title="instagram" href="https://www.instagram.com/effectivefoods" style={{backgroundColor: '#00693eff'}}>
                                    <img
                                      src={instagram}
                                      alt="Instagram"
                                      style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                                    />
                                  </a>
                                  </div>
                                  </div>
                </div>



                  <div className="tile">
                    <h3 className="title ef-home-title">{mainpitch.title}</h3>
                  </div>
                  <div className="tile">
                    {mainpitch.description}
                  </div>
                  <div className="tile">
                    <h3 className="title ef-home-title">{mainpitch2.title}</h3>
                  </div>
                  <div className="tile">
                    {mainpitch2.description}
                  </div>
                  <div className="tile">
                    <h3 className="title ef-home-title">{mainpitch3.title}</h3>
                  </div>
                  <div className="tile">
                    {mainpitch3.description}
                  </div>

                </div>
                <div className="columns">
                  <div id="help" className="column is-12">
                    <h1 className="has-text-weight-semibold is-size-2x">
                      {request}
                    </h1>
                    <p>{description}</p>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />




              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mission1: PropTypes.string,
  mission2: PropTypes.string,
  mainpitch: PropTypes.object,
  mainpitch2: PropTypes.object,
  mainpitch3: PropTypes.object,
  request: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  stats: PropTypes.object,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout pageMetadata = {frontmatter.pageMetadata}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mission1={frontmatter.mission1}
        mission2={frontmatter.mission2}
        mainpitch={frontmatter.mainpitch}
        mainpitch2={frontmatter.mainpitch2}
        mainpitch3={frontmatter.mainpitch3}
        request={frontmatter.request}
        description={frontmatter.description}
        intro={frontmatter.intro}
        stats={frontmatter.stats}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: {eq: "index-page"} }) {
      frontmatter {
        pageMetadata {
          title
          description
        }
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mission1
        mission2
        mainpitch {
          title
          description
        }
        mainpitch2 {
          title
          description
        }
        mainpitch3 {
          title
          description
        }
        request
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            header
            question
            color
            buttontext
            buttonlink
            text
          }
          heading
          description
        }
        stats {
          preventiontesters
          treatmenttesters
          reviewers
          supporters
          downloads
          prevention{
            enrolleddays
            enrolledpercent
            nosymptomstesters
            nosymptomsdays
            nosymptomspercent
            testedpositivetesters
            testedpositivedays
            testedpositivepercent
            mildsymptomstesters
            mildsymptomsdays
            mildsymptomspercent
            modsymptomstesters
            modsymptomsdays
            modsymptomspercent
            hospitalizationtesters
            hospitalizationsdays
            hospitalizationpercent
            deathtesters
            deathdays
            deathpercent
            closecasetester
            closecasedays
            closecasepercent
          }
          treatment{
            enrolleddays
            enrolledpercent
            nosymptomstesters
            nosymptomsdays
            nosymptomspercent
            testedpositivetesters
            testedpositivedays
            testedpositivepercent
            mildsymptomstesters
            mildsymptomsdays
            mildsymptomspercent
            modsymptomstesters
            modsymptomsdays
            modsymptomspercent
            hospitalizationtesters
            hospitalizationsdays
            hospitalizationpercent
            deathtesters
            deathdays
            deathpercent
            recoveredtester
            recovereddays
            recoveredpercent
          }
        }
      }
    }
  }
`
